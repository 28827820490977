import { createSelector } from '@reduxjs/toolkit';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { filterConditionsWithLocalizations } from '@/shared/embeddedLibs/conditionsAndMasks';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { reportsModel } from '@/entities/reports';
import { assetsModel } from '@/entities/assets';

import { RootState } from '@/app/model/store';

import {
  conditionIsActive,
  filterConditionsByReportID,
  getSlicesMasks,
  processLogicalConditions,
} from '../lib';

import { conditionAdapter } from './conditionSlice';

export const conditionSelectors = conditionAdapter.getSelectors(
  (state: RootState) => state.condition,
);

export const { selectAll, selectEntities, selectById } = conditionSelectors;

export const selectToothConditionIDs =
  (toothID: string) => (state: RootState) =>
    state.condition.teethGroup[toothID];

export const selectTeethConditionIDs =
  (teethIDs: string[]) => (state: RootState) =>
    teethIDs.flatMap((toothID) => state.condition.teethGroup[toothID]);

export const selectByToothID = (toothID: string) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectToothConditionIDs(toothID),
    (conditionEntities, toothConditionIDs) => {
      const toothConditions: Condition[] = [];

      toothConditionIDs?.forEach((id) => {
        if (conditionEntities[id]) {
          toothConditions.push(conditionEntities[id]);
        }
      });
      return toothConditions;
    },
  );

export const selectActiveByToothID = (toothID: string) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectToothConditionIDs(toothID),
    (conditionEntities, toothConditionIDs) => {
      const toothConditions: Condition[] = [];

      toothConditionIDs?.forEach((id) => {
        if (conditionEntities[id] && conditionIsActive(conditionEntities[id])) {
          toothConditions.push(conditionEntities[id]);
        }
      });
      return toothConditions;
    },
  );

export const selectToothLogicalConditions = (toothID: string) =>
  createSelector(selectByToothID(toothID), processLogicalConditions);

export const selectToothActiveLogicalConditions = (toothID: string) =>
  createSelector(selectActiveByToothID(toothID), processLogicalConditions);

export const selectByTeethIDs = (teethID: string[]) =>
  createSelector(
    conditionSelectors.selectEntities,
    selectTeethConditionIDs(teethID),
    (conditionEntities, teeththConditionIDs) => {
      const toothConditions: Condition[] = [];

      teeththConditionIDs?.forEach((id) => {
        if (conditionEntities[id]) {
          toothConditions.push(conditionEntities[id]);
        }
      });
      return toothConditions;
    },
  );

export const selectByReportID = createSelector(
  conditionSelectors.selectAll,
  (_: RootState, reportID: string) => reportID,
  filterConditionsByReportID,
);

// TODO: Collect MaxFax condition IDs map and use the map here
export const selectMaxFaxConditionsByReportID = (reportID: string) =>
  createSelector(conditionSelectors.selectAll, (conditions) =>
    conditions.filter(
      (condition) =>
        condition.ReportID === reportID &&
        condition.Attribution.case === 'MaxFax' &&
        condition.Attribution.value,
    ),
  );

export const selectConditionsWithLocalizations = createSelector(
  selectAll,
  reportsModel.selectors.selectCurrentReport,
  assetsModel.selectors.selectAll,
  (conditions, report, assets) => {
    if (!report || !assets) {
      return [];
    }

    return filterConditionsWithLocalizations(conditions, report, assets);
  },
);

export const selectConditionCodesWithMasks = createSelector(
  selectConditionsWithLocalizations,
  (conditions) => conditions.map((condition) => condition.Code),
);

export const selectSlicesMasks = (
  toothID: string,
  conditionCodes: ConditionCode[],
) => createSelector(selectByToothID(toothID), getSlicesMasks(conditionCodes));

export const selectLoading = (state: RootState) => state.condition.loading;
