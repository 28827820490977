import React, { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { find } from 'ramda';
import * as Slider from '@radix-ui/react-slider';

import { Button, Result, toastCaller, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { LocationStateType, PATHS } from '@/shared/config';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { scrollToTop } from '@/shared/lib';
import sadCatImage from '@/shared/assets/images/cats/sad.png';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import { getDisplayToothNumber, toothModel } from '@/entities/tooth';
import { assetsModel, useGetSelectedMedicalImages } from '@/entities/assets';
import {
  ConditionButton,
  ConditionGroups,
  conditionModel,
  useToothConditions,
} from '@/entities/condition';
import { organizationModel } from '@/entities/organization';

import { GPMedicalImageList } from '../MedicalImageList/MedicalImageList';
import { DragAndDropHint } from '../DragAndDropHint/DragAndDropHint';

import styles from './ToothCardFDA.module.scss';

type ToothCardProps = {
  className?: string;
  id: string;
  patientID: string;
  reportID: string;
  showViewerButton?: boolean;
  medicalImages?: React.ReactNode;
  dndComponent?: React.ReactNode;
  showSlices?: boolean;
  handleCardClick?: (ID: string) => void;
  isToothChartMode?: boolean;
  onSlicesClick?: (toothID: string) => void;
  periapicalCondition: Condition;
};

export const ToothCardFDA: FC<ToothCardProps> = (props) => {
  const {
    className,
    id,
    reportID,
    patientID,
    dndComponent,
    medicalImages,
    showViewerButton = true,
    showSlices = true,
    onSlicesClick,
    periapicalCondition,
  } = props;

  const [sliderValue, setSliderValue] = useState<number | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);

  const handleSliderChange = (value: number[]) => {
    setSliderValue(value[0]);
    setError(false);
  };

  const isFDANonAided = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_NonAidedVersion,
    ),
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));
  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, id),
  );
  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  const isAssetsExists = useAppSelector(
    assetsModel.selectors.selectIsAssetsExistsByToothID(tooth?.ID as string),
  );

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const displayToothNumber = getDisplayToothNumber(
    tooth?.Numeration?.ISO ?? 0,
    dentalNotationFormat,
  ).toString();
  const isSupernumerary = tooth?.Numeration?.SupernumeraryIndex ?? 0 > 0;
  const titleToothNumber = displayToothNumber + (isSupernumerary ? ' *' : '');

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const reportType = report?.Type;

  const isReportWithViewModes =
    reportType === ReportType.ReportType_IOXRay_GP ||
    reportType === ReportType.ReportType_Pano_Bitewings;

  const isToothFocused = tooth?.ID === activeToothID;
  const isFocusedView = isReportWithViewModes
    ? isToothFocused && navigationMode === FMXNavigationMode.ToothChart
    : isToothFocused;

  const { anatomyConditionItems, generalConditionItems } = useToothConditions({
    toothID: tooth?.ID ?? '',
  });

  const periapicalRadiolucencyCondition = generalConditionItems.find(
    (item) => item.code === ConditionCode.PeriapicalRadiolucency,
  );

  const isUserScoreUpdated = useMemo(() => {
    return (
      periapicalCondition &&
      periapicalCondition.Certainty?.UserScore !== sliderValue
    );
  }, [periapicalCondition, sliderValue]);

  useEffect(() => {
    if (
      periapicalCondition &&
      periapicalCondition.Certainty?.UserScore !== undefined
    ) {
      setSliderValue(periapicalCondition.Certainty?.UserScore);
    } else {
      setSliderValue(undefined);
    }
  }, [periapicalCondition]);

  const toothTitleCondition = find(
    (item) => item.group === ConditionGroups.toothType,
    anatomyConditionItems,
  );

  const isMissing = toothTitleCondition?.code === ConditionCode.Missing;

  const isMissingApproved = isMissing && tooth?.IsApproved;

  const isPeriapicalApproved =
    !isUserScoreUpdated &&
    periapicalCondition?.Certainty?.UserScore !== undefined &&
    tooth?.IsApproved;

  const showApproved = isMissingApproved || isPeriapicalApproved;

  useEffect(() => {
    if (isMissing) {
      setSliderValue(100);
    }
  }, [isMissing]);

  const selectedMedicalImages = useGetSelectedMedicalImages(
    tooth?.ID as string,
  ); // TODO add reportType to filter necessary images

  const approveUserScoreHandle = async () => {
    if (sliderValue === undefined) {
      setError(true);

      toastCaller({
        type: 'error',
        heading: <FormattedMessage id="error.heading" defaultMessage="Error" />,
        message:
          'Required evaluation of periapical radiolucency presence probability',
        autoClose: 10000,
      });

      return;
    }

    const conditionID = isMissing
      ? toothTitleCondition?.id
      : periapicalCondition?.ID;

    const { Condition, Tooth, Report } = await dispatch(
      reportsModel.thunks.setReportToothConditionUserScoreAndApproveTooth({
        ConditionID: conditionID as string,
        UserScore: sliderValue as number,
      }),
    ).unwrap();

    dispatch(reportsModel.actions.setNewestOne(Report as Report));
    dispatch(toothModel.actions.setOne(Tooth as Tooth));
    dispatch(conditionModel.actions.setNewestOne(Condition as Condition));
  };

  const handle3DSlicesClick = () => {
    scrollToTop();
    navigate(
      generatePath(PATHS.gpReportAllSlices, {
        patientID,
        reportID,
        toothID: tooth?.ID,
      }),
      {
        state: {
          ...locationState,
          toothID: tooth?.ID,
        },
        relative: 'path',
      },
    );
  };

  const handleSlicesClick = () => {
    if (onSlicesClick) {
      onSlicesClick(tooth?.ID as string);
    }
  };

  if (!toothTitleCondition) {
    return null;
  }

  return (
    <WidgetCard
      id={tooth?.ID}
      className={cn(
        styles.container,
        isFocusedView && styles.focused,
        className,
      )}
    >
      <header className={styles.header}>
        {toothTitleCondition && (
          <div className={cn('h4')}>
            {toothTitleCondition?.code === ConditionCode.Missing
              ? `Missing ${titleToothNumber}`
              : `Tooth ${titleToothNumber}`}
          </div>
        )}
      </header>
      {!isFDANonAided && periapicalRadiolucencyCondition && (
        <div className={cn(styles.conditions)}>
          <ConditionButton
            text={periapicalRadiolucencyCondition.text}
            conditionInterface={periapicalRadiolucencyCondition}
          />
        </div>
      )}
      {showSlices && (
        <div className={cn(styles.imageList)} onClick={handleSlicesClick}>
          {medicalImages || (
            <GPMedicalImageList
              medicalImages={selectedMedicalImages}
              toothID={tooth?.ID ?? ''}
            />
          )}
        </div>
      )}
      {dndComponent &&
        (isAssetsExists || medicalImages ? (
          <DragAndDropHint
            medicalImageQuantity={React.Children.count(medicalImages)}
          />
        ) : (
          <Result
            className={styles.noSlicesAtAll}
            icon={
              <img
                src={sadCatImage}
                width={130}
                height={130}
                alt={formatMessage({
                  id: 'imgAltText.sadCat',
                  defaultMessage: 'Sad cat',
                })}
              />
            }
            text={formatMessage({
              id: 'selectedMedicalImages.uploadImages',
              defaultMessage: `Please upload images first to add them here`,
            })}
          />
        ))}

      {!isMissing && (
        <div className={styles.sliderContainer}>
          <div className={styles.sliderHeader}>
            <div className={styles.label}>
              Your confidence in presence of Periapical radiolucency
            </div>
            <input
              type="number"
              min="0"
              max="100"
              value={sliderValue !== undefined ? sliderValue : ''}
              onChange={(e) => {
                const value = Math.min(
                  100,
                  Math.max(0, Number(e.target.value)),
                );
                setSliderValue(isNaN(value) ? undefined : value);
              }}
              placeholder="___"
              className={cn(
                styles.sliderInput,
                'h7',
                error && styles.inputError,
              )}
            />
            <div className={styles.sliderValue}>&nbsp;%</div>
          </div>
          <Slider.Root
            value={sliderValue !== undefined ? [sliderValue] : [50]}
            onValueChange={handleSliderChange}
            max={100}
            step={1}
            className={styles.sliderRoot}
          >
            <Slider.Track
              className={cn(
                styles.sliderTrack,
                sliderValue === undefined && styles.defaultTrack,
                error && styles.trackError,
              )}
            >
              <Slider.Range
                className={cn(
                  styles.sliderRange,
                  sliderValue === undefined && styles.defaultRange,
                  error && styles.rangeError,
                )}
              />
            </Slider.Track>
            <Slider.Thumb
              className={cn(
                styles.sliderThumb,
                sliderValue === undefined && styles.defaultThumb,
                error && styles.thumbError,
              )}
              aria-label="Probability"
            />
          </Slider.Root>
          {sliderValue !== undefined &&
            (sliderValue >= 50 ? (
              <div className={styles.redText}>
                You estimate that Periapical radiolucency is likely present
              </div>
            ) : (
              <div className={styles.greenText}>
                You estimate that Periapical radiolucency is likely not present
              </div>
            ))}
        </div>
      )}
      <footer className={styles.footer}>
        <div className={styles.leftFooterWrapper}></div>

        <div className={styles.rightFooterWrapper}>
          {reportType === ReportType.ReportType_CBCT_GP && showViewerButton && (
            <Button
              variant="tertiary"
              size="medium"
              onClick={handle3DSlicesClick}
            >
              <FormattedMessage id="toothCard.slices" defaultMessage="Slices" />
            </Button>
          )}
          <Button
            variant="secondary"
            size="medium"
            onClick={approveUserScoreHandle}
            disabled={!report?.YourPermissions?.CanChangeToothApproved}
            success={showApproved}
          >
            {showApproved ? (
              <FormattedMessage
                id="toothCard.approved"
                defaultMessage="Approved"
              />
            ) : (
              <FormattedMessage
                id="toothCard.approve"
                defaultMessage="Approve"
              />
            )}
          </Button>
        </div>
      </footer>

      {dndComponent}
    </WidgetCard>
  );
};
