import React, { ReactNode } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useMedia } from '@/shared/hooks';
import { Icon } from '@/shared/ui';
import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';

import {
  ChildConditionInterface,
  ConditionColor,
  ConditionDecisionChange,
  ConditionInterface,
  OnConditionHover,
} from '../../config/types';
import { ONE_OF_CONDITION_GROUPS } from '../../config';
import { conditionText } from '../../config/i18n';

import styles from './ConditionButton.module.scss';

// TODO: Extend from ConditionInterface
type ConditionButtonProps = {
  className?: string;
  rounded?: boolean;
  disabled?: boolean;
  isChild?: boolean;
  onHoverHandle?: (args: OnConditionHover) => void | undefined;
  showProbabilityText?: boolean;
  text: ReactNode;
  conditionInterface?: ConditionInterface | ChildConditionInterface;
  onClick?: (conditionID: string) => void;
  removeConditionHandle?: (conditionID: string) => void;
  updateConditionDecision?: ConditionDecisionChange;
};

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

// TODO: 1) Add memoization
// TODO: 2) Simplify props. Why do we need to pass prop one by one when we need the whole ConditionInterface?
const InternalConditionButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ConditionButtonProps
> = (props, ref) => {
  const {
    className,
    text,
    rounded = false,
    isChild = false,
    showProbabilityText = true,
    conditionInterface = {} as ConditionInterface,
    onClick,
    onHoverHandle,
    updateConditionDecision,
    disabled,
    ...restConditionItemProps
  } = props;

  const { color } = conditionInterface;

  const { isMobile } = useMedia();
  const { formatMessage } = useIntl();

  const removeDecisionHandle = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (typeof updateConditionDecision === 'function') {
      updateConditionDecision({
        conditionIDs: conditionInterface?.ids,
        parentConditionId: conditionInterface?.parentID,
        conditionCode: conditionInterface?.code,
        userDecision: Decision.NegativeDecision,
      });
    }
  };

  const hasOnHoverHandle = typeof onHoverHandle === 'function';

  const isOneOfCondition = conditionInterface.group
    ? ONE_OF_CONDITION_GROUPS.includes(
        conditionInterface.group as ConditionInterface['group'],
      )
    : false;

  return (
    <button
      ref={ref}
      {...restConditionItemProps}
      type="button"
      onClick={() => {
        if (typeof onClick === 'function') {
          // WARN: This function has specific task: open add condition modal and scroll to the condition toggle element with given ID.
          //       In case of click on child condition, scroll still should be to the parent condition.
          onClick(conditionInterface.parentID ?? conditionInterface.id);
        }
      }}
      onMouseEnter={() => {
        if (hasOnHoverHandle) {
          onHoverHandle({
            conditionItem: conditionInterface,
            isMouseEnter: true,
          });
        }
      }}
      onMouseLeave={() => {
        if (hasOnHoverHandle) {
          onHoverHandle({
            conditionItem: conditionInterface,
            isMouseEnter: false,
          });
        }
      }}
      className={cn(
        styles.container,
        isChild && styles.isChild,
        isMobile ? 'p3' : 'p2',
        colorStyle[color],
        rounded && styles.rounded,
        !showProbabilityText && styles.withoutBorder,
        !isOneOfCondition && styles.withRemoveButton,
        !hasOnHoverHandle && styles.disableHover,
        className,
      )}
      disabled={disabled}
    >
      <span>
        {text ||
          (conditionText[conditionInterface.code] &&
            formatMessage(conditionText[conditionInterface.code]))}
      </span>

      {!isOneOfCondition && (
        <div className={cn(styles.removeIconWrap, colorStyle[color])}>
          <Icon
            name="close"
            size={24}
            className={styles.removeIcon}
            onClick={removeDecisionHandle}
          />
        </div>
      )}
    </button>
  );
};

export const ConditionButton = React.forwardRef<
  HTMLButtonElement,
  ConditionButtonProps
>(InternalConditionButton);
