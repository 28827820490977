import React, { FC, memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { find, isEmpty, propEq, reject } from 'ramda';

import { Button, Result, Skeleton, Tooltip, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { LocationStateType, PATHS } from '@/shared/config';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { scrollToTop } from '@/shared/lib';
import sadCatImage from '@/shared/assets/images/cats/sad.png';

import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import { getDisplayToothNumber, toothModel } from '@/entities/tooth';
import { ModalID, modalModel } from '@/entities/modal';
import { assetsModel, useGetSelectedMedicalImages } from '@/entities/assets';
import {
  ConditionButton,
  ConditionGroups,
  OnConditionHover,
  useToothConditions,
} from '@/entities/condition';
import { organizationModel } from '@/entities/organization';
import { logicalConditionModel } from '@/entities/logicalCondition';

import { useApproveTooth } from '@/features/approveTooth';
import { CommentTooth, useCommentTooth } from '@/features/commentTooth';
import { useUpdateConditionDecision } from '@/features/updateConditionDecision';

import { useResetTooth } from '../hooks/useResetTooth';

import styles from './ToothCard.module.scss';
import { ConditionTitle } from './ContitionTitle/ConditionTitle';
import { GPMedicalImageList } from './MedicalImageList/MedicalImageList';
import { DragAndDropHint } from './DragAndDropHint/DragAndDropHint';

type ToothCardProps = {
  className?: string;
  id: string;
  patientID: string;
  reportID: string;
  showViewerButton?: boolean;
  medicalImages?: React.ReactNode;
  dndComponent?: React.ReactNode;
  showSlices?: boolean;
  handleCardClick?: (ID: string) => void;
  isToothChartMode?: boolean;
  onSlicesClick?: (toothID: string) => void;
};

export const InternalToothCard: FC<ToothCardProps> = (props) => {
  const {
    className,
    id,
    reportID,
    patientID,
    dndComponent,
    medicalImages,
    showViewerButton = true,
    showSlices = true,
    handleCardClick,
    isToothChartMode,
    onSlicesClick,
  } = props;

  const [approveButtonHovered, setApproveButtonHovered] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));
  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, id),
  );
  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );
  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );
  const isAssetsExists = useAppSelector(
    assetsModel.selectors.selectIsAssetsExistsByToothID(tooth?.ID as string),
  );
  const isShortCard = !showSlices;

  const displayToothNumber = getDisplayToothNumber(
    tooth?.Numeration?.ISO ?? 0,
    dentalNotationFormat,
  ).toString();
  const isSupernumerary = tooth?.Numeration?.SupernumeraryIndex ?? 0 > 0;
  const titleToothNumber = displayToothNumber + (isSupernumerary ? ' *' : '');

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const reportType = report?.Type;

  const isReportWithViewModes =
    reportType === ReportType.ReportType_IOXRay_GP ||
    reportType === ReportType.ReportType_Pano_Bitewings;

  const isToothFocused = tooth?.ID === activeToothID;
  const isFocusedView = isReportWithViewModes
    ? isToothFocused && navigationMode === FMXNavigationMode.ToothChart
    : isToothFocused;
  const isToothApproved = tooth?.IsApproved;
  const isToothApprovedMessage = isToothApproved && !approveButtonHovered;
  const isToothDisapproveMessage = isToothApproved && approveButtonHovered;

  const toothTitleClickHandle = () => {
    if (isToothChartMode && !isFocusedView) {
      if (handleCardClick) {
        handleCardClick(tooth?.ID as string);
      }
    }
  };

  const { anatomyConditionItems, generalConditionItems, conditionIsLoaded } =
    useToothConditions({
      toothID: tooth?.ID ?? '',
    });

  const isGeneralConditionItemsExist = generalConditionItems.length > 0;

  const toothTitleCondition = find(
    (item) => item.group === ConditionGroups.toothType,
    anatomyConditionItems,
  );

  const conditionItems = reject(
    propEq('group', ConditionGroups.toothType),
    anatomyConditionItems,
  );

  const selectedMedicalImages = useGetSelectedMedicalImages(
    tooth?.ID as string,
  ); // TODO add reportType to filter necessary images
  const { isApproveToothLoading, toggleApproveTooth } = useApproveTooth(
    tooth as Tooth,
  );

  const { updateConditionDecision } = useUpdateConditionDecision(
    tooth?.ID as string,
  );

  const {
    newComment,
    isCommentEditorVisible,
    isNewCommentLoading,
    closeAndResetTextInEditor,
    openChangeComment,
    changeCommentInEditor,
    saveComment,
  } = useCommentTooth(tooth?.Comment as string);

  const { resetTooth, isResetToothLoading } = useResetTooth(
    tooth?.ID as string,
  );

  // Move this up to the report page?
  const handleAddCondition = useCallback(
    (conditionID?: string) => {
      if (report) {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.AddCondition,
            data: {
              toothID: tooth?.ID as string,
              displayToothNumber: displayToothNumber,
              reportID: report?.ID,
              conditionID,
            },
          }),
        );
      }
    },
    [report, dispatch, tooth?.ID],
  );

  const handleApproveButtonHover = (isHovered: boolean) => {
    setApproveButtonHovered(isHovered);
  };

  const handle3DSlicesClick = () => {
    scrollToTop();
    navigate(
      generatePath(PATHS.gpReportAllSlices, {
        patientID,
        reportID,
        toothID: tooth?.ID,
      }),
      {
        state: {
          ...locationState,
          toothID: tooth?.ID,
        },
        relative: 'path',
      },
    );
  };

  const handleSlicesClick = () => {
    if (onSlicesClick) {
      onSlicesClick(tooth?.ID as string);
    }
  };

  const onConditionHoverHandler = ({
    conditionItem,
    isMouseEnter,
  }: OnConditionHover) => {
    const { hasLocalizations, toothID, code, parentID } = conditionItem;

    if (!hasLocalizations) {
      return;
    }

    if (isMouseEnter) {
      dispatch(
        logicalConditionModel.actions.setHoveredConditionLink({
          toothID: toothID as string,
          code,
          parentID: parentID as string,
        }),
      );
    } else {
      dispatch(
        logicalConditionModel.actions.setHoveredConditionLink({
          toothID: null,
          code: null,
          parentID: null,
        }),
      );
    }
  };

  if (!toothTitleCondition) {
    return null;
  }

  return (
    <WidgetCard
      id={tooth?.ID}
      className={cn(
        styles.container,
        isFocusedView && styles.focused,
        className,
      )}
    >
      <header className={styles.header}>
        {toothTitleCondition && (
          <ConditionTitle
            isToothChartMode={isToothChartMode}
            onHoverHandle={!activeToothID ? onConditionHoverHandler : () => {}}
            onClickHandle={toothTitleClickHandle}
            conditionItem={toothTitleCondition}
            toothNumber={titleToothNumber}
          />
        )}

        {isEmpty(anatomyConditionItems) && !conditionIsLoaded && (
          <Skeleton.Filter
            filtersQuantity={2}
            width={100}
            height={36}
            gap={4}
          />
        )}

        {/* Anatomy conditions list */}
        {conditionItems.length > 0 && (
          <div className={styles.conditions}>
            {conditionItems.map((conditionItem) => (
              <ConditionButton
                key={conditionItem.id}
                text={conditionItem.text}
                conditionInterface={conditionItem}
                onClick={handleAddCondition}
                updateConditionDecision={updateConditionDecision}
              />
            ))}
          </div>
        )}

        <Tooltip.Primary
          content={formatMessage({
            id: 'toothCard.restoreConditionsButton',
            defaultMessage: 'Restore the original conditions',
          })}
          side="bottom"
          triggerClassName={styles.restoreButtonWrapper}
        >
          <Button
            size="medium"
            variant="gray"
            icon="back"
            onClick={resetTooth}
            loading={isResetToothLoading}
          />
        </Tooltip.Primary>
      </header>

      {(isGeneralConditionItemsExist || isShortCard) && (
        <div className={cn(styles.conditions)}>
          {isGeneralConditionItemsExist &&
            generalConditionItems.map((conditionItem) => (
              <div key={conditionItem.id}>
                <ConditionButton
                  onHoverHandle={onConditionHoverHandler}
                  updateConditionDecision={updateConditionDecision}
                  text={conditionItem.text}
                  conditionInterface={conditionItem}
                  onClick={handleAddCondition}
                />
                {conditionItem?.childConditionInterfaces?.map(
                  (childConditionInterface) => (
                    <ConditionButton
                      updateConditionDecision={updateConditionDecision}
                      onHoverHandle={onConditionHoverHandler}
                      key={childConditionInterface.id}
                      text={childConditionInterface.text}
                      conditionInterface={childConditionInterface}
                      onClick={handleAddCondition}
                      isChild
                    />
                  ),
                )}
              </div>
            ))}

          {isShortCard && (
            <Button
              size="medium"
              variant="gray"
              icon="plus"
              onClick={() => handleAddCondition()}
            >
              {!isGeneralConditionItemsExist && (
                <FormattedMessage
                  id="toothCard.openAddConditionModalButton"
                  defaultMessage="Condition"
                />
              )}
            </Button>
          )}
        </div>
      )}

      {showSlices && (
        <div className={cn(styles.imageList)} onClick={handleSlicesClick}>
          {medicalImages || (
            <GPMedicalImageList
              medicalImages={selectedMedicalImages}
              toothID={tooth?.ID ?? ''}
            />
          )}

          {dndComponent &&
            (isAssetsExists || medicalImages ? (
              <DragAndDropHint
                medicalImageQuantity={React.Children.count(medicalImages)}
              />
            ) : (
              <Result
                className={styles.noSlicesAtAll}
                icon={
                  <img
                    src={sadCatImage}
                    width={130}
                    height={130}
                    alt={formatMessage({
                      id: 'imgAltText.sadCat',
                      defaultMessage: 'Sad cat',
                    })}
                  />
                }
                text={formatMessage({
                  id: 'selectedMedicalImages.uploadImages',
                  defaultMessage: `Please upload images first to add them here`,
                })}
              />
            ))}
        </div>
      )}

      <footer className={styles.footer}>
        <div className={styles.leftFooterWrapper}>
          <div className={styles.buttons}>
            {!isShortCard && (
              <Button
                size="medium"
                variant="gray"
                icon="plus"
                onClick={() => handleAddCondition()}
              >
                <FormattedMessage
                  id="toothCard.openAddConditionModalButton"
                  defaultMessage="Condition"
                />
              </Button>
            )}

            <Button
              size="medium"
              variant="gray"
              icon="pen"
              onClick={
                isCommentEditorVisible
                  ? closeAndResetTextInEditor
                  : openChangeComment
              }
            >
              <FormattedMessage
                id="toothCard.addCommentButton"
                defaultMessage="Comment"
              />
            </Button>
          </div>
        </div>

        <div className={styles.rightFooterWrapper}>
          {reportType === ReportType.ReportType_CBCT_GP && showViewerButton && (
            <Button
              variant="tertiary"
              size="medium"
              onClick={handle3DSlicesClick}
            >
              <FormattedMessage id="toothCard.slices" defaultMessage="Slices" />
            </Button>
          )}
          {/* TODO: [2|m] refactor and move to ApproveToothButton feature */}
          <Button
            variant="secondary"
            size="medium"
            onMouseMove={() => handleApproveButtonHover(true)}
            onMouseLeave={() => handleApproveButtonHover(false)}
            loading={isApproveToothLoading}
            onClick={toggleApproveTooth}
            disabled={!report?.YourPermissions?.CanChangeToothApproved}
            success={!approveButtonHovered && isToothApproved}
            danger={approveButtonHovered && isToothApproved}
          >
            {isToothApprovedMessage && (
              <FormattedMessage
                id="toothCard.approved"
                defaultMessage="Approved"
              />
            )}

            {isToothDisapproveMessage && (
              <FormattedMessage
                id="toothCard.disapprove"
                defaultMessage="Disapprove"
              />
            )}

            {!isToothApproved && (
              <FormattedMessage
                id="toothCard.approve"
                defaultMessage="Approve"
              />
            )}
          </Button>
        </div>
      </footer>

      <CommentTooth
        autoFocus
        comment={tooth?.Comment}
        newComment={newComment}
        isNewCommentLoading={isNewCommentLoading}
        isCommentEditorVisible={isCommentEditorVisible}
        onSaveComment={() => saveComment(tooth?.ID as string)}
        onCancel={closeAndResetTextInEditor}
        onOpenChangeComment={openChangeComment}
        onChangeCommentInEditor={changeCommentInEditor}
      />

      {dndComponent}
    </WidgetCard>
  );
};

export const ToothCard = memo(InternalToothCard);
