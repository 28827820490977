import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

type ConditionSplit = {
  conditions: Record<string, Condition[]>;
  childrenConditions: Record<string, Condition[]>;
};

// NOTE: This functuion split condition and children conditions.
//       Conditions grouping by Code, children conditions groupingby
//       by ParentCode to find them later to accumulate as Children
//       for LogicalCondition object
export const splitConditions = (conditions: Condition[]): ConditionSplit =>
  conditions.reduce(
    (acc: ConditionSplit, condition) => {
      if (condition.ParentID) {
        if (condition.ParentCode in acc.childrenConditions) {
          acc.childrenConditions[condition.ParentCode].push(condition);
        } else {
          acc.childrenConditions[condition.ParentCode] = [condition];
        }
      } else {
        if (condition.Code in acc.conditions) {
          acc.conditions[condition.Code].push(condition);
        } else {
          acc.conditions[condition.Code] = [condition];
        }
      }

      return acc;
    },
    { conditions: {}, childrenConditions: {} } as ConditionSplit,
  );
