import cn from 'classnames';
import { useIntl } from 'react-intl';
import { includes } from 'ramda';
import { useCallback } from 'react';

import { Icon, Spinner } from '@/shared/ui';
import { ReportGenerationErrorCode } from '@/shared/api/protocol-ts/model/dto_report_generation_error_codes_pb';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { useAppSelector } from '@/shared/hooks';

import { useNavigateToReport } from '@/entities/reports';
import { assetsModel } from '@/entities/assets';

import { useDownloadReportPDF } from '@/features/downloadReportPDF';
import { useRemoveReport } from '@/features/removeReport';
import { SupportTooltip } from '@/features/supportTooltip';

import { ReportMetaInfo } from '../../config/types';

import styles from './ReportInfoRow.module.scss';

export const ReportInfoRow = (props: ReportMetaInfo) => {
  const { id, reportType, patientID, date, status } = props;
  const error = status?.Failed;
  const isPending = status?.Pending;

  const { formatDate } = useIntl();
  const { downloadReportPDF, downloading } = useDownloadReportPDF(id);
  const removeReport = useRemoveReport();
  const navigateToReport = useNavigateToReport();

  const CBCTOrthoPDFUrl = useAppSelector(
    assetsModel.selectors.selectCBCTOrthoPDFUrl(id),
  );

  const hasError = includes(
    error?.Code,
    Object.values(ReportGenerationErrorCode),
  );

  const errorMessage =
    hasError && error?.Code !== undefined ? error?.Message : '';

  const showDownloadButton = status.Completed && !downloading;

  const downloadReportHandler = useCallback(() => {
    if (
      reportType === ReportType.ReportType_CBCT_OrthoSlides ||
      reportType === ReportType.ReportType_StudioOrtho
    ) {
      window.open(CBCTOrthoPDFUrl, '_blank', 'noopener,noreferrer');
    } else {
      downloadReportPDF();
    }
  }, [reportType, CBCTOrthoPDFUrl, downloadReportPDF]);

  const handleIDClick = useCallback(() => {
    if (status.Completed) {
      navigateToReport({ reportType, reportID: id, patientID });
    }
  }, [id, navigateToReport, patientID, reportType, status.Completed]);

  return (
    <div
      className={cn(styles.container, 'p2')}
      data-status-completed={status.Completed}
    >
      <div
        className={cn(
          styles.id,
          status.Completed && styles.completed,
          hasError && styles.error,
          isPending && styles.pending,
        )}
        onClick={handleIDClick}
      >
        ID {id}
      </div>
      <div className={styles.status}>
        {isPending && <Icon name="time" size={24} />}

        {hasError && (
          <SupportTooltip
            triggerClassName={cn(styles.status, styles.error)}
            errorMessage={errorMessage}
          >
            <Icon name="info" size={24} />
          </SupportTooltip>
        )}
      </div>
      <div className={styles.date}>{formatDate(date)}</div>
      {downloading && <Spinner size={32} primary />}
      {showDownloadButton && (
        <Icon
          name="download"
          onClick={downloadReportHandler}
          size={32}
          className={cn(styles.button, styles.downloadButton)}
        />
      )}
      <Icon
        name="delete"
        onClick={() => removeReport(id)}
        size={32}
        className={cn(styles.button, styles.deleteButton)}
      />
    </div>
  );
};
