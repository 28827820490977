import { defineMessages } from 'react-intl';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { OrderReportType } from '@/shared/config';

import { UploadStudyAssetType } from './types';

export const selectUploadStudyTypes = defineMessages<UploadStudyAssetType>({
  [AssetType.AssetType_Study_IntraoralXRay]: {
    id: 'uploadStudyType.intraoralXRay',
    defaultMessage: 'IOXRay',
  },
  [AssetType.AssetType_Study_CBCT]: {
    id: 'uploadAssetType.CBCT',
    defaultMessage: 'CBCT',
  },
  [AssetType.AssetType_Study_DentalPhoto]: {
    id: 'uploadAssetType.dentalPhoto',
    defaultMessage: 'Dental photo',
  },
  [AssetType.AssetType_Study_PanoramicXRay]: {
    id: 'uploadAssetType.panoramicXRay',
    defaultMessage: 'Pano',
  },
  [AssetType.AssetType_Study_IOS_Meshes]: {
    id: 'uploadAssetType.ios_stl',
    defaultMessage: 'Intraoral Scan',
  },
  [AssetType.AssetType_Document]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_User_Signature]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_Organization_EmployeeStamp]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_Organization_Stamp]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_Organization_Logo]: {
    id: 'uploadAssetType.document',
    defaultMessage: 'Document',
  },
  [AssetType.AssetType_User_Avatar]: {
    id: 'uploadAssetType.UserAvatar',
    defaultMessage: 'User Avatar',
  },
});

// TODO: Wait for clarifying texts for each type
export const uploadStudyHelpText = defineMessages<UploadStudyAssetType>({
  [AssetType.AssetType_Study_IntraoralXRay]: {
    id: 'uploadStudyHelpText.intraoralXRay',
    defaultMessage:
      'For FMX study, you can upload a series of images. You can also upload additional images to the created study later.',
  },
  [AssetType.AssetType_Study_CBCT]: {
    id: 'uploadStudyHelpText.CBCT',
    defaultMessage:
      'Please be careful when uploading CBCT files, you need to upload all files in the folder of study.',
  },
  [AssetType.AssetType_Study_DentalPhoto]: {
    id: 'uploadStudyHelpText.dentalPhoto',
    defaultMessage:
      'Upload the patient photo protocol. You can attach multiple images as well as video files.',
  },
  [AssetType.AssetType_Study_PanoramicXRay]: {
    id: 'uploadStudyHelpText.panoramicXRay',
    defaultMessage: 'For Pano study, you can upload one image file.',
  },
  [AssetType.AssetType_Study_IOS_Meshes]: {
    id: 'uploadStudyHelpText.ios_stl',
    defaultMessage:
      'Upload one of the following file types: *STL, *OBJ, *PLY, *DRC.',
  },
  [AssetType.AssetType_Document]: {
    id: 'uploadStudyHelpText.document',
    defaultMessage:
      'You can upload any type of file and share it with other colleagues, or just attach it to a patient profile to document the medical history.',
  },
  [AssetType.AssetType_User_Signature]: {
    id: 'uploadStudyHelpText.SignatureText',
    defaultMessage: 'For User Signature, you can upload one image file.',
  },
  [AssetType.AssetType_Organization_EmployeeStamp]: {
    id: 'uploadStudyHelpText.EmployeeStampText',
    defaultMessage: 'For User Stamp, you can upload one image file.',
  },
  [AssetType.AssetType_Organization_Stamp]: {
    id: 'uploadStudyHelpText.OrganizationStampText',
    defaultMessage: 'For Organization Stamp, you can upload one image file.',
  },
  [AssetType.AssetType_Organization_Logo]: {
    id: 'uploadStudyHelpText.OrganizationLogoText',
    defaultMessage: 'For Organization Logo, you can upload one image file.',
  },
  [AssetType.AssetType_User_Avatar]: {
    id: 'uploadStudyHelpText.AssetTypeUserAvatar',
    defaultMessage: 'For User Avatar, you can upload one image file.',
  },
});

export const orderReportTitle = defineMessages<OrderReportType>({
  CBCT: {
    id: 'orderReportTitle.CBCT',
    defaultMessage: 'Order CBCT AI Report',
  },
  Pano: {
    id: 'orderReportTitle.Pano',
    defaultMessage: 'Order Pano AI Report',
  },
  IOXRay: {
    id: 'orderReportTitle.IOXRay',
    defaultMessage: 'Order IOXRay AI Report',
  },
  '3DStudio': {
    id: 'orderReportTitle.3DStudio',
    defaultMessage: 'Order 3D Model',
  },
  ImplantStudio: {
    id: 'orderReportTitle.ImplantStudio',
    defaultMessage: 'Order Implant Report',
  },
  OrthoStudio: {
    id: 'orderReportTitle.OrthoStudio',
    defaultMessage: 'Order Ortho AI Report',
  },
});
