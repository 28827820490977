import { cloneDeep, isArray, mergeWith } from 'lodash';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { Certainty } from '@/shared/api/protocol-ts/model/dto_report_certainty_pb';

import { LogicalCondition } from '../config';

function mergeCustomizer(objValue: unknown, srcValue: unknown) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }

  // NOTE: Logical condition should have highest ModelScore from all merged conditions
  if ((objValue as Certainty)?.ModelScore) {
    return new Certainty({
      ...(objValue as Certainty),
      ModelScore: Math.max(
        (objValue as Certainty).ModelScore,
        (srcValue as Certainty).ModelScore,
      ),
    });
  }
}

export const makeLogicalCondition = (
  conditions: Condition[],
): LogicalCondition =>
  conditions.reduce((logicalCondition: LogicalCondition, condition) => {
    const clone = cloneDeep(condition);
    const currentLogicalCondition = {
      ...clone,
      IDs: [clone.ID],
      Children: [],
    } as unknown as LogicalCondition;

    return mergeWith(
      logicalCondition,
      currentLogicalCondition,
      mergeCustomizer,
    );
  }, {} as LogicalCondition);
