import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import {
  Button,
  Column,
  Icon,
  Layout,
  Tooltip,
  WidgetLayout,
} from '@/shared/ui';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { LocationStateType } from '@/shared/config';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { ServiceType } from '@/shared/api/protocol-ts/api/billing/dto_services_pb';
import { gender } from '@/shared/i18n';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { getName, trackPageView } from '@/shared/lib';

import { ConfirmModal, ModalID, modalModel } from '@/entities/modal';
import {
  getPatientYourPermissions,
  patientModel,
  useFormatPatientDateOfBirth,
} from '@/entities/patient';
import { reportsModel } from '@/entities/reports';
import { filterStudiesByType, studyModel } from '@/entities/study';
import { userModel } from '@/entities/user';
import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';

import { Footer } from '@/features/footer';

import { Header } from '@/widgets/Header';
import { PatientInfo } from '@/widgets/PatientInfo';
import { PatientModalForm } from '@/widgets/PatientModalForm';
import { StudyCard } from '@/widgets/StudyCard';
import {
  StudyInformation,
  useStudyInfoModal,
} from '@/widgets/StudyInformation';
import { UploadStudyModalForm } from '@/widgets/UploadStudyModalForm';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { OrderImplantOrOrthoModelModal_DEPRECATED } from '@/widgets/OrderModelModal';
import { Order3DSegmentronModal } from '@/widgets/Order3DSegmentronModal';
import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';

import { SegmentronModelWidget } from './ui/SegmentronModelWidget/SegmentronModelWidget';
import { getSortedStudiesByColumn } from './utils/getSortedStudiesByColumn';
import styles from './PatientProfile.module.scss';
import { OrderImplantOrOrthoModel_DEPRECATED } from './ui/OrderModel/OrderImplantOrOrthoModel';

const SegmentationReportTypes: ReportType[] = [
  ReportType.ReportType_CBCT_Segmentation,
  ReportType.ReportType_CBCT_IOS_Superimposition,
  ReportType.ReportType_IOS_Segmentation,
  ReportType.ReportType_StudioImplant,
  ReportType.ReportType_StudioOrtho,
];

/**
 * @deprecated
 */
export const PatientProfile_DEPRECATED: FC = () => {
  const { patientID = '' } = useParams();

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const {
    currentStudyID,
    openStudyInfoModal,
    closeStudyModal,
    showStudyInfoModal,
  } = useStudyInfoModal();

  const { isMobile } = useMedia();

  const location = useLocation();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const showOrder3DReportsWidget = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_Order3DReports,
    ),
  );

  const services = useAppSelector(
    billingModel.selectors.selectActiveServicesList,
  );

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const initialStudies = useAppSelector(
    studyModel.selectors.selectByPatientID(patientID),
  );

  const isFDA = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  const filteredStudies = useMemo(() => {
    if (isFDA) {
      return initialStudies?.filter(
        (study) => study.Type === StudyType.StudyType_CBCT,
      );
    }

    return initialStudies;
  }, [isFDA, initialStudies]);

  const patientReports = useAppSelector(
    reportsModel.selectors.selectByPatientID(patientID),
  );
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);

  const patientDateOfBirth = patient?.PersonalData?.DateOfBirth;

  const formattedPatientAge = useFormatPatientDateOfBirth({
    patientDateOfBirth,
    pluralized: true,
  });

  const segmentationReports = patientReports
    .filter(
      (report) =>
        SegmentationReportTypes.includes(report.Type) &&
        !report.Deleted?.Deleted,
    )
    .sort((a, b) => a.Type - b.Type);

  const locationState = location?.state as LocationStateType;

  const scrollToLastPositionPatientProfile =
    locationState?.lastPositionPatientProfile;

  const hasSegmentronReports = !!segmentationReports.length;

  useEffect(() => {
    if (scrollToLastPositionPatientProfile) {
      window.scrollTo(0, scrollToLastPositionPatientProfile);
    }
  }, [scrollToLastPositionPatientProfile]);

  useEffect(() => {
    trackPageView('PatientProfile Page Viewed');
  }, []);

  // Maybe simpler just group studies by type and render each type when we need?
  const CBCTStudies = filterStudiesByType(StudyType.StudyType_CBCT)(
    filteredStudies,
  );
  const IOSStudies = filterStudiesByType(StudyType.StudyType_IOS_Meshes)(
    filteredStudies,
  );

  const panoramicStudies = filterStudiesByType(
    StudyType.StudyType_PanoramicXRay,
  )(filteredStudies);
  const ioxrayStudies = filterStudiesByType(StudyType.StudyType_IntraoralXRay)(
    filteredStudies,
  );

  const collectedStudies = useMemo(() => {
    const studiesArray = [
      { id: 1, data: CBCTStudies },
      { id: 2, data: panoramicStudies },
      { id: 3, data: ioxrayStudies },
    ];

    return studiesArray.sort((firstStudies, secondStudies) => {
      const firstStudiesFirstItem = firstStudies.data[0];
      const firstItemCreatedAt = firstStudiesFirstItem?.Created?.At;

      const secondStudiesFirstItem = secondStudies.data[0];
      const secondItemCreatedAt = secondStudiesFirstItem?.Created?.At;

      if (!firstItemCreatedAt || !secondItemCreatedAt) {
        return 0;
      }

      return (
        new Date(secondItemCreatedAt.toDate()).getTime() -
        new Date(firstItemCreatedAt.toDate()).getTime()
      );
    });
  }, [CBCTStudies, panoramicStudies, ioxrayStudies]);

  const { leftColumn, rightColumn } = getSortedStudiesByColumn(
    collectedStudies,
    hasSegmentronReports,
  );

  const openUploadStudyModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.UploadStudy_DEPRECATED,
        data: { patientID },
      }),
    );
  }, [dispatch, patientID]);

  if (isEmpty(user) || patient === undefined) {
    return null;
  }

  const {
    CanUploadCBCT,
    CanUploadDentalPhoto,
    CanUploadSTL,
    CanUploadPanoramicXRay,
    CanUploadIOXRay,
    CanViewStudies,
    CanEditPatient,
    CanViewReports,
    CanRequestReport,
  } = getPatientYourPermissions(patient);

  const handleEditPatient = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.PatientModalForm,
        data: {
          patientID,
        },
      }),
    );
  };

  const patientGender = formatMessage(gender[patient?.Gender]);

  const patientExternalID = `${formatMessage({
    id: 'patientInfo.externalID',
    defaultMessage: 'ID',
  })}: ${patient?.ExternalID}`;

  const patientEmail = patient?.PersonalData?.Emails[0];

  const isAnyUploadsAvailable =
    (CanUploadCBCT && services[ServiceType.ServiceType_Upload_CBCT]) ||
    (CanUploadIOXRay && services[ServiceType.ServiceType_Upload_IOXRay]) ||
    (CanUploadPanoramicXRay &&
      services[ServiceType.ServiceType_Upload_Panorama]) ||
    (CanUploadSTL && services[ServiceType.ServiceType_Upload_STL]) ||
    (CanUploadDentalPhoto &&
      services[ServiceType.ServiceType_Upload_DentalPhoto]);

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main className={styles.main}>
          <div className={styles.header}>
            <div className={styles.patientInfo}>
              <h1 className={cn(styles.patientName, 'h3')}>
                {getName(
                  patient.PersonalData?.FirstName,
                  patient.PersonalData?.LastName,
                  { userLocale },
                )}
              </h1>

              <div className={styles.patientInfoDetails}>
                <div className={styles.patientInfoDetailsWrapper}>
                  <p className="p2">{formattedPatientAge}</p>
                  <p className="p2">{patientGender}</p>
                  {patient.ExternalID ? (
                    <p className="p2">{patientExternalID}</p>
                  ) : null}
                </div>
                {patientEmail && (
                  <p className={cn(styles.patientEmail, 'p2')}>
                    {patientEmail}{' '}
                  </p>
                )}
                {CanEditPatient && (
                  <Tooltip.Primary
                    content={
                      <FormattedMessage
                        id="global.edit"
                        defaultMessage="Edit"
                      />
                    }
                  >
                    <Icon
                      name="pen"
                      size={24}
                      onClick={handleEditPatient}
                      className={styles.penIcon}
                    />
                  </Tooltip.Primary>
                )}
              </div>
            </div>

            <Button
              disabled={!isAnyUploadsAvailable}
              icon="plus"
              size={isMobile ? 'medium' : 'large'}
              onClick={openUploadStudyModal}
              className={styles.newStudyButton}
            >
              <FormattedMessage
                id="PatientProfile.openUploadStudyModal"
                defaultMessage="Upload new study"
              />
            </Button>
          </div>

          <WidgetLayout>
            <Column className={styles.column}>
              <PatientInfo patientID={patientID} />

              {CanViewStudies &&
                leftColumn.map(
                  (currentStudies) =>
                    !!currentStudies.data.length && (
                      <StudyCard
                        key={currentStudies.id}
                        studies={currentStudies.data}
                        onViewDetails={openStudyInfoModal}
                        canRequestReport={CanRequestReport}
                      />
                    ),
                )}
            </Column>

            <Column className={styles.column}>
              {/* TODO: [1/l] return the widget when the segmentron subscriptions will be ready */}
              {!isFDA && CanRequestReport && showOrder3DReportsWidget && (
                <OrderImplantOrOrthoModel_DEPRECATED />
              )}
              {!isFDA &&
                CanViewReports &&
                hasSegmentronReports &&
                segmentationReports.map((report) => (
                  <SegmentronModelWidget key={report.ID} report={report} />
                ))}
              {CanViewStudies &&
                rightColumn.map(
                  (currentStudies) =>
                    !!currentStudies.data.length && (
                      <StudyCard
                        key={currentStudies.id}
                        studies={currentStudies.data}
                        onViewDetails={openStudyInfoModal}
                        canRequestReport={CanRequestReport}
                      />
                    ),
                )}
            </Column>
          </WidgetLayout>

          <StudyInformation
            studyID={currentStudyID as string}
            isOpen={showStudyInfoModal}
            onClose={closeStudyModal}
          />

          <PatientModalForm />

          <UploadStudyModalForm />

          <ConfirmModal />
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>

      {/* MODALS */}
      <AboutLabelingModal />
      <GetFullAccessModal />
      <OrderImplantOrOrthoModelModal_DEPRECATED
        cbctStudies={CBCTStudies}
        iosStudies={IOSStudies}
      />
      <Order3DSegmentronModal CBCTStudies={CBCTStudies} />
    </Layout>
  );
};
