import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { getStudySelectStyles, Modal, Select } from '@/shared/ui';
import { Study } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { RequestReportReq } from '@/shared/api/protocol-ts/api/core/svc_report_pb';

import { ModalID, modalModel } from '@/entities/modal';
import { reportsModel } from '@/entities/reports';
import { useGetOptionsFromStudies } from '@/entities/study';

import {
  OrderImplantOrOrthoModelFormPayload,
  orderImplantOrOrthoModelFormSchema,
} from '../config/formSchema';

import styles from './OrderImplantOrOrthoModelModal.module.scss';

export type OrderImplantOrOrthoModelModalProps = {
  cbctStudies: Study[];
  iosStudies: Study[];
};

// TODO: Delete after completely move to the new patient profile page
/**
 * @deprecated
 */
export const OrderImplantOrOrthoModelModal_DEPRECATED: FC<
  OrderImplantOrOrthoModelModalProps
> = ({ cbctStudies, iosStudies }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useAppDispatch();
  const {
    visible,
    data: { title, type: modelType },
  } = useAppSelector((state) => state.modal.OrderOrthoORImplantModal);

  const { formatMessage } = useIntl();

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.OrderOrthoORImplantModal));
  }, [dispatch]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<OrderImplantOrOrthoModelFormPayload>({
    resolver: yupResolver(orderImplantOrOrthoModelFormSchema),
  });

  const cbctOptions = useGetOptionsFromStudies(cbctStudies);
  const iosOptions = useGetOptionsFromStudies(iosStudies);

  const requestReport = async (
    cbctID: string,
    iosID?: string,
    dentalPhotoStudyID?: string,
  ) => {
    if (modelType) {
      await dispatch(
        reportsModel.thunks.requestReport({
          Request: {
            Input: {
              case: modelType,
              value: {
                CBCTStudyID: cbctID,
                IOSMeshesStudyID: iosID as string,
                DentalPhotoStudyID: dentalPhotoStudyID as string,
              },
            },
          },
        } as RequestReportReq),
      );
    }
  };

  // Set default
  useEffect(() => {
    if (cbctOptions.length) {
      reset({ cbct: cbctOptions[0].value });
    }
  }, [reset, cbctStudies]);

  const onSubmit: SubmitHandler<OrderImplantOrOrthoModelFormPayload> = async (
    data,
  ) => {
    setIsSubmitting(true);

    const { cbct, ios } = data;

    try {
      await requestReport(cbct, ios);

      handleClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      borderless
      okButtonProps={{
        type: 'submit',
        form: 'orderModel',
        loading: isSubmitting,
        disabled: !isValid,
      }}
      okButtonText={formatMessage({
        id: 'global.order',
        defaultMessage: 'Order',
      })}
    >
      <form id="orderModel" onSubmit={handleSubmit(onSubmit)}>
        <p className="p2 bold">
          <FormattedMessage
            id="orderModel.step1"
            defaultMessage="Step 1: Select CBCT"
          />
        </p>

        <Controller
          control={control}
          name="cbct"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Select
              styles={getStudySelectStyles()}
              options={cbctOptions}
              isRequired
              ref={ref}
              value={`${value}`}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <p className="p2 bold">
          <FormattedMessage
            id="orderModel.step2"
            defaultMessage="Step 2: Select IOS you want to combine with CBCT study"
          />
        </p>

        <Controller
          control={control}
          name="ios"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Select
              label={formatMessage({
                id: 'orderModel.selectStudyWithUpperAndLowerJaws',
                defaultMessage: 'Select study with upper and lower jaws',
              })}
              styles={getStudySelectStyles()}
              options={iosOptions}
              ref={ref}
              value={`${value}`}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
};
